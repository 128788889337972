import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Button = ({ label, url, class_name }) => {
    return (
        <Link
            className={class_name ? 'button ' + class_name : 'button'}
            to={url}
        >
            {label}
        </Link>
    )
}

export default Button
